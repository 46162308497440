<template>
  <!-- pc端 -->
  <template v-if="deviceType == 'pc'">
    <!-- 按钮 -->
    <div class="cutomers" @click="showView(false)" v-if="data.autoshow">
      <el-button type="primary" :icon="ChatDotRound"> 在线咨询 </el-button>
      <el-image src="https://scrm.jzsaas.com/emoji/kefu.png"></el-image>
    </div>
    <template v-else>
      <template v-if="data.center">
        <!-- 离线弹窗 pc-->
        <div class="customerOutLine_server" v-if="!statusing">
          <div class="customerOutLine_server_header">
            <span>客服已离线</span>
            <el-icon @click="showView(true)"><Close /></el-icon>
          </div>
          <div class="customerOutLine_server_content">
            <el-input
              v-model="offFrom.customerName"
              placeholder="请输入您的姓名"
              clearable
            ></el-input>
            <el-input
              v-model="offFrom.customerMobile"
              clearable
              placeholder="请输入您的联系电话"
            ></el-input>
            <el-input
              v-model="offFrom.message"
              style="margin-top: 30px"
              clearable
              placeholder="请填写留言内容"
              type="textarea"
              class="pc_textarea"
            ></el-input>
            <el-button type="primary" @click="submit">提交留言</el-button>
          </div>
        </div>
        <!-- 非离线弹窗 pc -->
        <div class="customerOutLine_server_pc" v-else>
          <div class="customerOutLine_server_header">
            <div>
              <el-avatar :size="30" :src="avatarURL" />
              {{ avatarName }}<span v-if="isreply"> [对方正在回复...]</span>
            </div>
            <el-icon @click="showView(true)"><Close /></el-icon>
          </div>
          <div class="customerOutLine_server_content">
            <ChatCotent
              :messageList.sync="messageList"
              :assistantId="assistantId"
              ref="scrollMap"
              :avatar="data"
              @setMessageList="setMessageList"
              @refreshTagList="refreshTagList"
              @disabledFrom="disabledFrom"
              @sendMessage="sendMessage"
              :tagList="tagList"
            >
            </ChatCotent>
            <ChatRight :intro="intro" />
          </div>
        </div>
      </template>
      <template v-else>
        <!-- 离线弹窗 mobile -->
        <div class="customerOutLine_server_mobile" v-if="!statusing">
          <div class="customerOutLine_server_header">
            <div>客服已离线</div>
            <el-icon @click="showView(true)"><Close /></el-icon>
          </div>
          <div class="customerOutLine_server_content">
            <el-input
              v-model="offFrom.customerName"
              placeholder="请输入您的姓名"
              clearable
            ></el-input>
            <el-input
              v-model="offFrom.customerMobile"
              clearable
              placeholder="请输入您的联系电话"
            ></el-input>
            <el-input
              v-model="offFrom.message"
              style="margin-top: 30px"
              clearable
              placeholder="请填写留言内容"
              type="textarea"
              class="model_textarea"
            ></el-input>
            <el-button type="primary" @click="submit">提交留言</el-button>
          </div>
        </div>
        <!-- 非离线弹窗 mobile -->
        <div class="customerOutLine_server_mobile" v-else>
          <div class="customerOutLine_server_header">
            <div>
              <el-avatar :size="30" :src="avatarURL" />
              <span style="margin-left: 10px"
                >{{ avatarName
                }}<span v-if="isreply"> [对方正在回复...]</span></span
              >
            </div>
            <el-icon @click="showView(true)"><Close /></el-icon>
          </div>
          <div class="customerOutLine_server_content">
            <ChatContent_mobile
              :messageList.sync="messageList"
              :websocketObj.sync="websocketObj"
              :assistantId="assistantId"
              ref="scrollMap"
              :avatar="data"
              :deviceType="deviceType"
              @setMessageList="setMessageList"
              @refreshTagList="refreshTagList"
              @disabledFrom="disabledFrom"
              @sendMessage="sendMessage"
              :tagList="tagList"
            />
          </div>
        </div>
      </template>
    </template>
  </template>
  <!-- 手机端 -->
  <template v-if="deviceType == 'Mobile'">
    <!-- 按钮 -->
    <el-button
      type="primary"
      class="cutomer"
      v-if="data.autoshow"
      :icon="ChatDotRound"
      @click="showView(false)"
      >在线咨询</el-button
    >
    <template v-else>
      <!-- 离线弹窗 -->
      <div class="customerOutLine_server_mobile" v-if="!statusing">
        <div class="customerOutLine_server_header">
          <div>客服已离线</div>
          <el-icon @click="showView(true)"><Close /></el-icon>
        </div>
        <div class="customerOutLine_server_content">
          <el-input
            v-model="offFrom.customerName"
            placeholder="请输入您的姓名"
            clearable
          ></el-input>
          <el-input
            v-model="offFrom.customerMobile"
            clearable
            placeholder="请输入您的联系电话"
          ></el-input>
          <el-input
            v-model="offFrom.message"
            style="margin-top: 30px; height: 200px"
            clearable
            placeholder="请填写留言内容"
            type="textarea"
            class="model_textareas"
          ></el-input>
          <el-button type="primary" @click="submit">提交留言</el-button>
        </div>
      </div>
      <!-- 非离线弹窗 -->
      <div
        class="customerOutLine_server_mobile customerOutLine_server_mobiles"
        v-else
      >
        <div class="customerOutLine_server_header">
          <div>
            <el-avatar :size="30" :src="avatarURL" />
            <span style="margin-left: 10px"
              >{{ avatarName
              }}<span v-if="isreply"> [对方正在回复...]</span></span
            >
          </div>
          <el-icon @click="data.autoshow = true"><Close /></el-icon>
        </div>
        <div class="customerOutLine_server_content">
          <ChatContent_mobile
            :messageList.sync="messageList"
            :assistantId="assistantId"
            ref="scrollMap"
            :avatar="data"
            :deviceType="deviceType"
            @setMessageList="setMessageList"
            @refreshTagList="refreshTagList"
            @disabledFrom="disabledFrom"
            @sendMessage="sendMessage"
            :tagList="tagList"
          />
        </div>
      </div>
    </template>
  </template>
</template>

<script setup>
import { onBeforeMount, ref, watch } from "vue";
import { ChatDotRound, Close } from "@element-plus/icons-vue";
import ChatCotent from "./components/chatCotent";
import ChatRight from "./components/chatRight.vue";
import ChatContent_mobile from "./components/chatContent_mobile";
import { v4 as uuidv4 } from "uuid";
import { useWebSocket } from "@vueuse/core";
import { getNowFormatDate } from "@/util/index";
import { ElMessage } from "element-plus";
import { messageCreate, enableAssistant, introConfig } from "@/api/index";
const hostURL = process.env.VUE_APP_BASE_API;
// const hostURL = "http://192.168.0.76:48080";

const avatarURL = ref("");
const avatarName = ref("");
const scrollMap = ref(null);
const isreply = ref(false);
/*ifrom传过来的值*/
const data = ref({});
/*有无客服*/
const statusing = ref(false);
/*用户的唯一标识存在缓存中*/
const sessionId = ref("");
const tenantId = ref("");
const assistantId = ref("");

/*手机端还是pc端*/
const deviceType = ref("pc");

/*接收的消息*/
const messageList = ref([]);

/*websocket对象*/
const websocketObj = ref({});

/*客服简介*/
const intro = ref("");
/*标签*/
const tagList = ref([]);
const tagClone = ref([]);

const showView = (val) => {
  data.value.autoshow = val;
  window.parent.postMessage(
    {
      data: val,
      center: data.value.center,
    },
    "*"
  );
};
/*页面加载处理*/
onBeforeMount(async () => {
  data.value = getQueryParams();
  let bool = JSON.parse(data.value.autoshow);
  data.value.autoshow = !bool;
  data.value.center = JSON.parse(data.value.center);

  let res = await enableAssistant({ tenantId: data.value.tenantId });

  statusing.value = res.data.data;

  if (res.data.data && res.data.data.introConfigId) {
    let results = await introConfig({
      tenantId: data.value.tenantId,
      id: res.data.data.introConfigId,
    });
    if (results.data.data) {
      intro.value = results.data.data.content;
    }
  }

  const isMoble = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (isMoble) {
    deviceType.value = "Mobile";
  } else {
    deviceType.value = "pc";
  }

  /*客户状态存在*/
  if (statusing.value) {
    data.value.data = res.data.data;
    avatarURL.value = res.data.data.avator;
    avatarName.value = res.data.data.name || "官方客服";
    tenantId.value = data.value.tenantId;
    assistantId.value = res.data.data.id;
    getStoreState();
    initUser();
  }
});

/*获取缓存数据*/
const getStoreState = () => {
  if (localStorage.getItem("messageMap")) {
    messageList.value = JSON.parse(localStorage.getItem("messageMap"));
  } else {
    messageList.value = [];
  }
};
/*设置缓存数据*/
const setStoreState = (val) => {
  localStorage.setItem("messageMap", val);
};
/*用户的唯一参数uuid*/
const initUser = () => {
  const uuid = localStorage.getItem("USER_UUID");
  if (uuid) {
    sessionId.value = uuid;
  } else {
    sessionId.value = uuidv4();
    localStorage.setItem("USER_UUID", sessionId.value);
  }
  /*
  客户端： 建立ws连接的时候， clientType=1-pc; 2-h5, 3-app
  */
  const clientType = deviceType.value == "pc" ? 1 : 2;
  const url =
    (hostURL + "/infra/ws").replace("http", "ws") +
    `?userType=1&clientType=${clientType}&tenantId=${tenantId.value}&assistantId=${assistantId.value}&sessionId=${sessionId.value}`;
  initWebSocket(url);
};
const websocketObjData = ref({});
/*初始化websocket*/
const initWebSocket = (url) => {
  /** 发起 WebSocket 连接 */
  const { data } = (websocketObj.value = useWebSocket(url, {
    autoReconnect: false,
    heartbeat: {
      interval: 10000,
    },
  }));
  websocketObjData.value = data.value;
  welcomeMessage();
};
// 发送消息
const sendMessage = (val) => {
  websocketObj.value.send(val);
};
// 欢迎语
const welcomeMessage = () => {
  // 1.1 先 JSON 化 message 消息内容
  const messageContent = JSON.stringify({
    text: "",
    toUserId: assistantId.value,
    messageType: 1,
  });
  // 1.2 再 JSON 化整个消息
  const jsonMessage = JSON.stringify({
    type: "service-chat-message-send",
    content: messageContent,
  });
  // 2. 最后发送消息
  websocketObj.value.send(jsonMessage);
};

watch(
  () => websocketObj.value,
  (values) => {
    if (Object.keys(values).length == 0) {
      return;
    }
    const datas = values.data;
    try {
      /*心跳*/
      if (datas === "pong") {
        return;
      }
      const jsonMessage = JSON.parse(datas);
      if (!jsonMessage) {
        return;
      }
      const type = jsonMessage.type;
      const content = JSON.parse(jsonMessage.content);
      /*接收的消息*/
      if (type == "service-chat-message-receive") {
        content.time = getNowFormatDate();
        content.avatar = avatarURL.value;
        content.type = "you";
        // 普通消息
        if (content.messageType == 3) {
          isreply.value = true;
          let timer = 0;
          if (content.content.length <= 10) {
            timer = 5e2;
          } else if (
            content.content.length <= 20 &&
            content.content.length > 10
          ) {
            timer = 1e3;
          } else {
            timer = 2e3;
          }
          setTimeout(() => {
            isreply.value = false;
            messageList.value.push(content);
            setStoreState(JSON.stringify(messageList.value));
            scrollMap.value?.scrollDom();
          }, timer);
        }
        // 欢迎语
        else if (content.messageType == 4) {
          content.faList = JSON.parse(content.content?.faList) || [];
          if (!messageList.value.some((obj) => obj.messageType === 4)) {
            messageList.value.push(content);
          }
        }
        //标签
        else if (content.messageType == 5) {
          tagList.value = JSON.parse(content?.content) || [];
          if (tagClone.value.length == 0)
            tagClone.value = JSON.parse(content?.content) || [];
        }
        //表单类型
        else if (content.messageType == 7) {
          content.content.formContent.forEach((element) => {
            element.value = "";
          });
          content.disabled = false;
          messageList.value.push(content);
        }
        setStoreState(JSON.stringify(messageList.value));
        scrollMap.value?.scrollDom();
        // 客服离线
        if (content.messageType == 2) {
          statusing.value = false;
          websocketObj.value.close ? websocketObj.value.close() : "";
        }
      }
    } catch (error) {
      ElMessage.error("处理消息发生异常：" + data.value);
      console.error(error);
    }
  },
  { deep: true, immediate: true }
);
/*根据时间 禁用表单*/
const disabledFrom = (time) => {
  messageList.value.forEach((element) => {
    if (element.time == time && element.messageType == 7) {
      element.disabled = true;
    }
  });
  setStoreState(JSON.stringify(messageList.value));
};
/*重置*/
const refreshTagList = () => {
  tagList.value = tagClone.value;
};
/*操作消息列表*/
const setMessageList = (val) => {
  messageList.value.push(val);
  setStoreState(JSON.stringify(messageList.value));
  scrollMap.value.scrollDom();
};
/*获取地址栏参数*/
const getQueryParams = () => {
  let params = {};
  let parser = document.createElement("a");
  parser.href = window.location.href;
  var query = parser.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }
  return params;
};

const offFrom = ref({
  customerName: "",
  customerMobile: "",
  message: "",
  tenantId: "",
});
/*提交留言*/
const submit = () => {
  if (sessionStorage.getItem("messageCreate")) {
    ElMessage.warning("请不要重复留言!");
    return;
  }
  const { customerName, customerMobile, message } = offFrom.value;
  if (!customerName) {
    ElMessage.warning("请输入您的姓名");
    return;
  }
  if (!customerMobile) {
    ElMessage.warning("请输入您的联系电话");
    return;
  }
  let pattern = /^1[3456789]\d{9}$/;
  let phoneBoole = pattern.test(customerMobile);
  if (!phoneBoole) {
    ElMessage.warning("请输入正确的电话号码");
    return;
  }
  if (!message) {
    ElMessage.warning("请输入留言内容");
    return;
  }
  offFrom.value.tenantId = data.value.tenantId;
  messageCreate({ ...offFrom.value }).then((res) => {
    if (res.data.code == 0) {
      ElMessage.success("留言成功,我们会尽快与您取得联系!");
      data.value.autoshow = true;
      offFrom.value.customerName = "";
      offFrom.value.customerMobile = "";
      offFrom.value.message = "";
      sessionStorage.setItem("messageCreate", true);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};
</script>

<style lang="scss" scoped>
.customerOutLine_server {
  background-color: #fff;
}
.cutomer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 20px 30px;
  border-radius: 21px;
}
.cutomers {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 21px;
  .el-button {
    padding: 20px 30px;
    border-radius: 21px;
    z-index: 9999;
  }
  .el-image {
    top: -105px;
    left: 23px;
    width: 87px;
    height: 117px;
    z-index: -1;
    position: absolute;
  }
}
.customerOutLine_server_mobiles {
  width: 100% !important;
}
.customerOutLine_server_mobile {
  position: fixed;
  z-index: 999;
  right: 0px;
  border-radius: 4px;
  transition: all 0.3s ease 0s;
  bottom: 0px;
  width: 378px;
  height: 645px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 15px 0px;
  opacity: 1;
  .customerOutLine_server_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 18px;
    background: linear-gradient(270deg, #1890ff, #3875ea);
    color: #fff;
    div {
      display: flex;
      align-items: center;
    }
  }
  .customerOutLine_server_content {
    height: calc(100% - 50px);
    padding: 17px 18px;
    .el-input {
      height: 40px;
      line-height: 40px;
      margin-top: 30px;
    }
    .el-button {
      width: 100%;
      margin-top: 30px;
    }
    .el-textarea {
      height: 280px;
    }
  }
}
.customerOutLine_server_pc {
  width: 840px;
  position: fixed;
  z-index: 999;
  inset: 0px;
  border-radius: 8px;
  transition: none 0s ease 0s;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 15px 0px;
  margin: auto;
  display: block;
  overflow: hidden;
  background: #fff;
  .customerOutLine_server_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 18px;
    background: linear-gradient(270deg, #1890ff, #3875ea);
    color: #fff;
    div {
      display: flex;
      align-items: center;
      .el-avatar {
        margin-right: 10px;
      }
    }
  }
  .customerOutLine_server_content {
    display: flex;
    height: calc(100% - 50px);
  }
}
.customerOutLine_server {
  position: fixed;
  z-index: 999;
  inset: 0px;
  border-radius: 8px;
  transition: none 0s ease 0s;
  width: 378px;
  height: 645px;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 15px 0px;
  margin: auto;
  display: block;
  overflow: hidden;
  background: #fff;
  .customerOutLine_server_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 18px;
    background: linear-gradient(270deg, #1890ff, #3875ea);
    color: #fff;
    div {
      display: flex;
      align-items: center;
      .el-avatar {
        margin-right: 10px;
      }
    }
  }
  .customerOutLine_server_content {
    background: #fff;
    padding: 17px 18px;
    .el-input {
      height: 40px;
      line-height: 40px;
      margin-top: 30px;
    }
    .el-button {
      width: 100%;
      margin-top: 30px;
    }
    .el-textarea {
      height: 120px;
    }
  }
}
:deep(.pc_textarea) {
  .el-textarea__inner {
    height: 120px;
  }
}
:deep(.model_textarea) {
  .el-textarea__inner {
    height: 280px;
  }
}
:deep(.model_textareas) {
  .el-textarea__inner {
    height: 200px;
  }
}
</style>
