<template>
  <div class="avatar-upload">
    <label for="avatar-upload-input">
      <slot></slot>
    </label>
    <input
      type="file"
      @change="updataAvatar"
      id="avatar-upload-input"
      accept=".jpg, .jpeg, .png, .gif"
      style="display: none"
      ref="inputFile"
    />
  </div>
</template>

<script setup lang="ts">
import { defineEmits, ref } from "vue";
import { ElMessage } from "element-plus";
import { uploadImg } from "@/api/index";
const emit = defineEmits(["update:modelValue"]);
const inputFile = ref(null);
// 头像上传
const updataAvatar = async (event) => {
  const listType = ["image/jpeg", "image/png", "image/gif"];
  const types = event.target.files[0]?.type;
  const imgType = listType.indexOf(types);
  const imgSize = event.target.files[0].size / 1024 / 1024 < 5;
  if (imgType == -1) {
    ElMessage.warning("上传图片不符合所需的格式！");
    return;
  }
  if (!imgSize) {
    ElMessage.warning(`上传图片大小不能超过5M！`);
    return;
  }
  // 获取文件
  let file = event.target.files[0];
  let formData = new FormData();
  formData.append("file", file);
  const res = await uploadImg(formData);
  if (res.data.code == 0) {
    emit("update:modelValue", res.data.data);
  } else {
    ElMessage.error(res.data.msg);
  }
  inputFile.value.value = null;
};
</script>
