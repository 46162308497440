import request from '@/util/serve';

/*客服留言*/ 
export const messageCreate = (data:Object) => {
  return request({
    url: '/api/admin-api/service/leave-message/create',
    method: 'post',
    data
  })
} 
/*上传图片*/ 
export const uploadImg = (data:Object) => {
  return request({
    url: '/api/open-api/infra/file/simple-upload',
    // url: '/api/admin-api/infra/file/upload',
    method: 'post',
    data
  })
}
/*客服状态*/
export const enableAssistant = (params:Object) => {
  return request({
    url: '/api/admin-api/service/assistant/get-enable-assistant',
    method: 'get',
    params
  })
} 

// 客服简介
export const introConfig = (params:Object) => {
  return request({
    url: '/api/open-api/service/intro-config/get',
    method: 'get',
    params
  })
} 