/*当前时间*/ 
function getNowFormatDate() {
    let date = new Date()
    let obj = {
        year: date.getFullYear(), //获取完整的年份(4位)
        month: date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate: date.getDate(), // 获取当前日(1-31)
        hour: date.getHours()>=10?date.getHours():'0'+date.getHours(), //获取当前小时(0 ~ 23)
        minute: date.getMinutes()>=10?date.getMinutes():'0'+date.getMinutes(), //获取当前分钟(0 ~ 59)
        second: date.getSeconds()>=10?date.getSeconds():'0'+date.getSeconds() //获取当前秒数(0 ~ 59)
      }
    return `${obj.year}/${obj.month}/${obj.strDate} ${obj.hour}:${obj.minute}:${obj.second}`
}
export {getNowFormatDate,}